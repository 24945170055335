import { Heading as Cmpt } from "@/components/ui/heading";

function Heading({type, colour, ...props }) {
	return <Cmpt 
				type={type}
				colour={colour}
				{...props}
			/>;
}

export default Heading;